import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../store/actions/actions';
import Footer from './Footer'
import ActionLoading from "../Common/ActionLoading";
import {Affix, Layout} from 'antd';
import {useTranslation} from "react-i18next";
import LeftSidebar from "./LeftSidebar";
import MainHeader from "./Header";
import VerifySubscribePlan from "../Common/VerifySubscribePlan";
import {useLazyQuery} from "@apollo/client";
import {AREAS_BY_BRANCH_QUERY} from "../../api/areas/areaQuery";
import {TABLES_BY_BRANCH_QUERY} from "../../api/tables/tableQuery";
import {PRODUCT_CATEGORIES_QUERY} from "../../api/product_categories/categoryQuery";
import {PRODUCTS_POS_QUERY} from "../../api/products/productQuery";
import {CUSTOMERS_FOR_POS_QUERY} from "../../api/customers/customerQuery";
import {PRINT_FORMS_QUERY} from "../../api/print_form/printerQuery";
import _ from "lodash";
import {usePouchDB} from "../../databases/usePouchDB";
import {useDatabase} from "../../databases/useDatabase";
import {PRICE_BOOKS_QUERY} from "../../api/price_book/priceBookQuery";
import {E_INVOICE_DEFAULT_QUERY} from "../../api/e_invoice/eInvoiceConfigQuery";
import {createEInvoiceClient} from "../../restClient";
import WebSocketClient from "../../store/websocket";

const {Content} = Layout;

const BaseHorizontal = (props) => {
    const {t} = useTranslation();
    const {db, ready, alive, error} = usePouchDB();
    const [loading, documents] = useDatabase(db);
    const [currentLink, setCurrentLink] = useState(null);

    const branchActivate = props.user.branchActivate;

    const onLoadAreasOnline = () => {
        getAreas({variables: {branchId: branchActivate.branchId}})
            .then((result) => {
                    if (result.data && result.data.areasByBranch) {
                        let results = result.data.areasByBranch;
                        let areas = [];
                        for (let i = 0; i < results.length; i++) {
                            db.get(`area_${results[i].areaId}`).then((doc) => {
                                if (doc) {
                                    areas.push({
                                        _id: `area_${results[i].areaId}`,
                                        _rev: doc._rev,
                                        table: 'areas',
                                        areaId: parseInt(results[i].areaId),
                                        areaName: results[i].areaName,
                                        areaEnable: results[i].areaEnable,
                                        branchId: parseInt(branchActivate.branchId),
                                    })
                                }
                            }).catch(() => {
                                areas.push({
                                    _id: `area_${results[i].areaId}`,
                                    table: 'areas',
                                    areaId: parseInt(results[i].areaId),
                                    areaName: results[i].areaName,
                                    areaEnable: results[i].areaEnable,
                                    branchId: parseInt(branchActivate.branchId),
                                })
                            }).then(() => {
                                db.bulkDocs(areas);
                            });
                        }
                    }
                }
            )
    }

    const onLoadTablesOnline = () => {
        getTables({variables: {branchId: branchActivate.branchId}})
            .then((result) => {
                    if (result.data && result.data.tablesByBranch) {
                        let results = result.data.tablesByBranch;
                        let tables = [];
                        for (let i = 0; i < results.length; i++) {
                            db.get(`table_${results[i].tableId}`).then((doc) => {
                                if (doc) {
                                    tables.push({
                                        _id: `table_${results[i].tableId}`,
                                        _rev: doc._rev,
                                        table: 'tables',
                                        tableId: parseInt(results[i].tableId),
                                        tableName: results[i].tableName,
                                        tableAreaId: results[i].tableArea ? results[i].tableArea.areaId : null,
                                        tableSerial: results[i].tableSerial ? results[i].tableSerial : 0,
                                        tableEnable: results[i].tableEnable,
                                        branchId: parseInt(branchActivate.branchId),
                                    })
                                }
                            }).then(

                            ).catch(() => {
                                tables.push({
                                    _id: `table_${results[i].tableId}`,
                                    table: 'tables',
                                    tableId: parseInt(results[i].tableId),
                                    tableName: results[i].tableName,
                                    tableAreaId: results[i].tableArea ? results[i].tableArea.areaId : null,
                                    tableSerial: results[i].tableSerial ? results[i].tableSerial : 0,
                                    tableEnable: results[i].tableEnable,
                                    branchId: parseInt(branchActivate.branchId),
                                })
                            }).then(() => {
                                tables = _.orderBy(tables, ['tableSerial'], ['asc']);
                                db.bulkDocs(tables).then(() => {
                                });
                            });
                        }
                    }
                }
            );
    }

    const onLoadCategories = () => {
        getCategories({variables: {branchId: branchActivate.branchId}})
            .then((result) => {
                    if (result.data && result.data.productCategories) {
                        let results = result.data.productCategories;

                        let categories = [];
                        for (let i = 0; i < results.length; i++) {
                            db.get(`category_${results[i].categoryId}`).then((doc) => {
                                if (doc) {
                                    categories.push({
                                        _id: `category_${results[i].categoryId}`,
                                        _rev: doc._rev,
                                        table: 'categories',
                                        categoryId: parseInt(results[i].categoryId),
                                        categoryName: results[i].categoryName,
                                        categoryEnable: results[i].categoryEnable,
                                    })
                                }
                            }).then(

                            ).catch(() => {
                                categories.push({
                                    _id: `category_${results[i].categoryId}`,
                                    table: 'categories',
                                    categoryId: parseInt(results[i].categoryId),
                                    categoryName: results[i].categoryName,
                                    categoryEnable: results[i].categoryEnable,
                                })
                            }).then(() => {
                                db.bulkDocs(categories).then(() => {
                                });
                            });
                        }
                    }
                }
            );
    }

    const onLoadProducts = () => {
        getProducts({
            variables:
                {
                    limit: 10000,
                    order: '',
                    sort: 'desc',
                    keyword: '',
                }
        })
            .then((result) => {
                if (result.data && result.data.products) {
                    let results = result.data.products;
                    let products = [];
                    for (let i = 0; i < results.length; i++) {
                        let productImage = '/img/avatar-placeholder.png';
                        if (results[i].productImage && results[i].productImage !== '') {
                            productImage = process.env.REACT_APP_BASE_URL + results[i].productImage;
                        }

                        /*if(results[i].productImage && results[i].productImage !== '') {
                             getBase64Image(process.env.REACT_APP_BASE_URL + results[i].productImage, (dataURL) => {
                                productImage = dataURL;

                                 let productSellPriceBook = 0
                                 if(priceBookSelected){
                                     let productPriceBook = results[i].ppkProduct;
                                     if(productPriceBook && productPriceBook.length > 0){
                                         let ppb = productPriceBook.filter(item => item.ppkId === priceBookSelected)
                                         if(ppb && ppb.length > 0){
                                             productSellPriceBook = ppb[0].ppkPrice || 0
                                         }
                                     }
                                 }

                                 products.push({
                                     _id: `product_${results[i].productId}`,
                                     table: 'products',
                                     productId: parseInt(results[i].productId),
                                     productName: results[i].productName,
                                     //productImage: productImage,
                                     productNameSearch: results[i].productName.toLowerCase(),
                                     productSku: results[i].productSku,
                                     productBarcode: results[i].productBarcode,
                                     productSellPrice: results[i].productSellPrice,
                                     productSellPriceBook: productSellPriceBook,
                                     productUnit: {
                                         unitId: results[i].productUnit.unitId,
                                         unitName: results[i].productUnit.unitName,
                                     },
                                     productCategoryId: results[i].productCategory ? results[i].productCategory.categoryId : null,
                                     /!*_attachments: productImage ? {
                                         [results[i].productSku+'.png']: {
                                             content_type: "image/png",
                                             data: productImage
                                         }
                                     } : null,*!/
                                     productConvertUnit: results[i].pcuProduct,
                                     productPriceBook: results[i].ppkProduct,
                                 })
                            })
                        }else {

                        }*/

                        let productSellPriceBook = 0

                        let productNameSearch = results[i].productSku.toLowerCase() + '-' + results[i].productName.toLowerCase();

                        let productCategoryId = null;
                        if (results[i].productCategory && results[i].productCategory.length > 0) {
                            productCategoryId = results[i].productCategory[0].categoryId;
                        }

                        db.get(`product_${results[i].productId}`).then((doc) => {
                            if (doc) {
                                products.push(
                                    {
                                        _id: `product_${results[i].productId}`,
                                        _rev: doc._rev,
                                        table: 'products',
                                        productId: parseInt(results[i].productId),
                                        productName: results[i].productName,
                                        productImage: productImage,
                                        productNameSearch: productNameSearch,
                                        productSku: results[i].productSku,
                                        productBarcode: results[i].productBarcode,
                                        productSellPrice: results[i].productSellPrice,
                                        productSellPriceBook: productSellPriceBook,
                                        productUnit: {
                                            unitId: results[i].productUnit.unitId,
                                            unitName: results[i].productUnit.unitName,
                                        },
                                        productCategoryId: productCategoryId,
                                        productConvertUnit: results[i].pcuProduct,
                                        productPriceBook: results[i].ppkProduct,
                                        productSellAvailable: results[i].productSellAvailable,
                                        productEnable: results[i].productEnable,
                                    }
                                )
                            } else {

                            }
                        }).then(() => {
                        }).catch(() => {
                            products.push(
                                {
                                    _id: `product_${results[i].productId}`,
                                    table: 'products',
                                    productId: parseInt(results[i].productId),
                                    productName: results[i].productName,
                                    productImage: productImage,
                                    productNameSearch: productNameSearch,
                                    productSku: results[i].productSku,
                                    productBarcode: results[i].productBarcode,
                                    productSellPrice: results[i].productSellPrice,
                                    productSellPriceBook: productSellPriceBook,
                                    productUnit: {
                                        unitId: results[i].productUnit.unitId,
                                        unitName: results[i].productUnit.unitName,
                                    },
                                    productCategoryId: productCategoryId,
                                    productConvertUnit: results[i].pcuProduct,
                                    productPriceBook: results[i].ppkProduct,
                                    productSellAvailable: results[i].productSellAvailable,
                                    productEnable: results[i].productEnable,
                                }
                            )
                        }).then(() => {
                            db.bulkDocs(products).then(() => {
                            });
                        });
                    }
                }
            });
    }

    const onLoadPrintForm = () => {
        getPrintForm()
            .then((result) => {
                if (result.data && result.data.printForms) {
                    let results = result.data.printForms;
                    let printForms = [];
                    for (let i = 0; i < results.length; i++) {
                        db.get(`print_form_${results[i].printFormId}`).then((doc) => {
                            if (doc) {
                                printForms.push({
                                    _id: `print_form_${results[i].printFormId}`,
                                    _rev: doc._rev,
                                    table: 'print_form',
                                    printFormId: parseInt(results[i].printFormId),
                                    printFormType: results[i].printFormType,
                                    printFormContent: results[i].printFormContent,
                                    printFormDefault: results[i].printFormDefault,
                                })
                            }
                        }).then(

                        ).catch(() => {
                            printForms.push({
                                _id: `print_form_${results[i].printFormId}`,
                                table: 'print_form',
                                printFormId: parseInt(results[i].printFormId),
                                printFormType: results[i].printFormType,
                                printFormContent: results[i].printFormContent,
                                printFormDefault: results[i].printFormDefault,
                            })
                        }).then(() => {
                            db.bulkDocs(printForms).then(() => {
                            });
                        });
                    }
                }
            });
    }

    const onLoadCustomersOnline = () => {
        getCustomers({variables: {keyword: ""}}).then(result => {
            if (result.data && result.data.customersForPos) {
                let results = result.data.customersForPos;
                for (let i = 0; i < results.length; i++) {

                    let customer_arr = [];

                    let customersSearch = results[i].customerCode.toLowerCase() + '-' + results[i].customerName.toLowerCase();

                    if (results[i].customerMobile) {
                        customersSearch += '-' + results[i].customerMobile.toLowerCase();
                    }

                    db.get(`customer_${results[i].customerId}`).then((doc) => {
                        if (doc) {
                            customer_arr.push({
                                _id: `customer_${results[i].customerId}`,
                                _rev: doc._rev,
                                table: 'customers',
                                customerId: parseInt(results[i].customerId),
                                customerCode: results[i].customerCode,
                                customerName: results[i].customerName,
                                customersSearch: customersSearch,
                                customerAvatar: results[i].customerAvatar,
                                customerMobile: results[i].customerMobile,
                                customerEmail: results[i].customerEmail,
                                customerEnable: results[i].customerEnable,
                            })
                        }
                    }).then(

                    ).catch(() => {
                        customer_arr.push({
                            _id: `customer_${results[i].customerId}`,
                            table: 'customers',
                            customerId: parseInt(results[i].customerId),
                            customerCode: results[i].customerCode,
                            customerName: results[i].customerName,
                            customersSearch: customersSearch,
                            customerAvatar: results[i].customerAvatar,
                            customerMobile: results[i].customerMobile,
                            customerEmail: results[i].customerEmail,
                            customerEnable: results[i].customerEnable,
                        })
                    }).then(() => {
                        db.bulkDocs(customer_arr).then(() => {
                        });
                    });
                }
            }
        });
    }

    const onLoadPriceBook = () => {
        getPriceBook().then(result => {
            if (result.data && result.data.priceBooks) {
                let results = result.data.priceBooks;
                let price_book_arr = [];
                for (let i = 0; i < results.length; i++) {
                    db.get(`price_book_${results[i].pbkId}`).then((doc) => {
                        if (doc) {
                            price_book_arr.push({
                                _id: `price_book_${results[i].pbkId}`,
                                _rev: doc._rev,
                                table: 'price_book',
                                pbkId: parseInt(results[i].pbkId),
                                pbkName: results[i].pbkName,
                                pbkEffectiveFrom: results[i].pbkEffectiveFrom,
                                pbkEffectiveTo: results[i].pbkEffectiveTo,
                                pbkRangeBranch: results[i].pbkRangeBranch,
                                pbkRangeStaff: results[i].pbkRangeStaff,
                                pbkRangeCustomer: results[i].pbkRangeCustomer,
                                pbkAllowOutRange: results[i].pbkAllowOutRange,
                                pbkOutRangeAlert: results[i].pbkOutRangeAlert,
                                pbkEnable: results[i].pbkEnable,
                            })
                        }
                    }).then(

                    ).catch(() => {
                        price_book_arr.push({
                            _id: `price_book_${results[i].pbkId}`,
                            table: 'price_book',
                            pbkId: parseInt(results[i].pbkId),
                            pbkName: results[i].pbkName,
                            pbkEffectiveFrom: results[i].pbkEffectiveFrom,
                            pbkEffectiveTo: results[i].pbkEffectiveTo,
                            pbkRangeBranch: results[i].pbkRangeBranch,
                            pbkRangeStaff: results[i].pbkRangeStaff,
                            pbkRangeCustomer: results[i].pbkRangeCustomer,
                            pbkAllowOutRange: results[i].pbkAllowOutRange,
                            pbkOutRangeAlert: results[i].pbkOutRangeAlert,
                            pbkEnable: results[i].pbkEnable,
                        })
                    }).then(() => {
                        db.bulkDocs(price_book_arr).then(() => {
                        });
                    });
                }
            }
        })
    }

    const initFirstLoad = () => {
        props.actions.changeSetting("isActionLoading", true);
        props.actions.changeSetting('isFirstLoad', false);

        onLoadAreasOnline()
        onLoadTablesOnline()
        onLoadCustomersOnline()
        onLoadCategories()
        onLoadProducts()
        onLoadPrintForm()
        onLoadPriceBook()

        if (!loadingAreas && !loadingTables
            && !loadingCategories && !loadingProducts
            && !loadingCustomers && !loadingPrintForm && !loadingPriceBook) {
            props.actions.changeSetting("isActionLoading", false);
            props.actions.changeSetting('isFirstLoad', false);
        }
    }

    const onLoadEInvoiceMobiToken = () => {
        getEInvoiceDefault().then((result) => {
            if (result.data && result.data.eInvoicesDefault) {
                if (result.data.eInvoicesDefault) {
                    let defaultConfig = result.data.eInvoicesDefault[0];
                    if (defaultConfig && defaultConfig.eInvoicesConnect) {
                        if (props.einvoices[defaultConfig.eInvoicesSupplier].link !== defaultConfig.eInvoicesLink) {
                            createEInvoiceClient(defaultConfig.eInvoicesSupplier, defaultConfig.eInvoicesLink);
                            props.actions.changeEInvoiceLink(defaultConfig.eInvoicesSupplier, defaultConfig.eInvoicesLink);
                        }
                    }
                }
            }
        })
    }

    const [getAreas, {loading: loadingAreas}] = useLazyQuery(AREAS_BY_BRANCH_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    const [getTables, {loading: loadingTables}] = useLazyQuery(TABLES_BY_BRANCH_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    const [getCategories, {loading: loadingCategories}] = useLazyQuery(PRODUCT_CATEGORIES_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    const [getProducts, {loading: loadingProducts}] = useLazyQuery(PRODUCTS_POS_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    const [getCustomers, {loading: loadingCustomers}] = useLazyQuery(CUSTOMERS_FOR_POS_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    const [getPrintForm, {loading: loadingPrintForm}] = useLazyQuery(PRINT_FORMS_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    const [getPriceBook, {loading: loadingPriceBook}] = useLazyQuery(PRICE_BOOKS_QUERY, {
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    const [getEInvoiceDefault, {loading: loadingEInvoices}] = useLazyQuery(E_INVOICE_DEFAULT_QUERY, {
        variables: {
            companiesId: props.user.company.companyId,
        },
        fetchPolicy: 'no-cache'
    })

    // Cập nhật live sync data change các tài khoản, thiết bị
    const onSyncData = (receive) => {
        const syncCompanyInfo = receive?.data?.syncData;

        if (syncCompanyInfo?.change && syncCompanyInfo.change.companyInfo) {
            const newInfo = syncCompanyInfo.change.companyInfo;
            props.actions.changeUserLogin("company", {
                "companyId": parseInt(props.user.company.companyId),
                "companyName": newInfo.companyName,
                "companyCode": newInfo.companyCode,
                "companyIdTaxNumber": newInfo.companyIdTaxNumber,
                "companyLogo": newInfo.companyLogo?.url,
                "companySubscriptionPlan": newInfo.companySubscriptionPlan,
                "companyExpiryDate": newInfo.companyExpiryDate,
                "companyOwner": newInfo.companyOwner,
                "companyManager": newInfo.companyManager
            });
        }
    };

    useEffect(() => {
        // listening change from WebSocket
        const removeListener = WebSocketClient.addMessageListener(onSyncData);

        // Cleanup on unmount
        return () => {
            removeListener();
        };
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.einvoices.mobifone.link) || _.isEmpty(props.einvoices.vnpt.link) || _.isEmpty(props.einvoices.viettel.link)) {
            onLoadEInvoiceMobiToken();
        }

        if (props.settings.isFirstLoad) {
            initFirstLoad();
        }

        props.actions.changeSetting('horizontal', true);
        return () => {
            props.actions.changeSetting('horizontal', false);
        }
    }, [props.einvoices]);

    return (
        <Layout>
            <Affix offsetTop={0}>
                <MainHeader/>
            </Affix>

            <Layout>
                <LeftSidebar/>

                <Layout style={{padding: '24px 24px 0px 24px'}}>
                    <Content>
                        <VerifySubscribePlan/>

                        {props.children}
                    </Content>
                </Layout>
            </Layout>

            <Footer/>

            <ActionLoading status={true}/>
        </Layout>
    );
};

BaseHorizontal.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    user: PropTypes.object,
    configs: PropTypes.object,
    einvoices: PropTypes.object
};

const mapStateToProps = state => ({
    settings: state.settings,
    configs: state.configs,
    user: state.user,
    einvoices: state.einvoices
});
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BaseHorizontal);
