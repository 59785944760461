import {Breadcrumb, Button, Col, Row, Space, Typography} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import {useTranslation} from 'react-i18next';

const {Title} = Typography;

const HeaderAction = (props) => {
    const {id, headerFixed, breadcrumb, onlyView, loadingData, loadingCreate, loadingUpdate, permission} = props;
    const {t} = useTranslation();

    const _handeSave = () => {
        if (props.onSave) {
            props.onSave();
        }
    }

    const _handeApprove = () => {
        if (props.onApprove) {
            props.onApprove();
        }
    }

    const _handeNotApprove = () => {
        if (props.onNotApprove) {
            props.onNotApprove();
        }
    }

    const _handePayment = () => {
        if (props.onPayment) {
            props.onPayment();
        }
    }

    const _handeSaveContinue = () => {
        if (props.onSaveContinue) {
            props.onSaveContinue();
        }
    }

    const _handeChange = () => {
        if (props.onChange) {
            props.onChange();
        }
    }

    const _handeCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    useEffect(() => {

    }, [onlyView, loadingData, loadingUpdate, loadingCreate])

    return (
        <div>
            <Row>
                {props.title &&
                    <Col span={24}>
                        <Title level={4}>{props.title}</Title>
                    </Col>
                }
                <Col span={24}>
                    <Breadcrumb items={breadcrumb} iconFontSize={5}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Space>
                        {(!onlyView) &&
                            <Button type="primary" icon={<SaveOutlined/>}
                                    onClick={_handeSave}
                                    id="btnSave"
                                    disabled={loadingData || loadingCreate || loadingUpdate}
                                    loading={loadingData || loadingCreate || loadingUpdate}
                            >
                                {t('button.save')}
                            </Button>
                        }

                        {(!props.onlyPayment) && onlyView && props?.onApprove &&
                            <Button type="primary" icon={<CheckCircleOutlined/>}
                                    onClick={_handeApprove}
                                    color={'primary'}
                                    variant="outlined"
                                    id="btnApprove"
                                    disabled={loadingData || loadingCreate || loadingUpdate}
                                    loading={loadingData || loadingCreate || loadingUpdate}
                            >
                                {t('button.approve')}
                            </Button>
                        }

                        {(!props.onlyPayment) && onlyView && props?.onNotApprove &&
                            <Button type="primary" icon={<CloseCircleOutlined/>}
                                    color={'danger'}
                                    variant="outlined"
                                    onClick={_handeNotApprove}
                                    id="btnNotApprove"
                                    disabled={loadingData || loadingCreate || loadingUpdate}
                                    loading={loadingData || loadingCreate || loadingUpdate}
                            >
                                {t('button.notApprove')}
                            </Button>
                        }

                        {props.onlyPayment && onlyView && props?.onPayment &&
                            <Button type="primary" icon={<CheckCircleOutlined/>}
                                    color={'primary'}
                                    variant="outlined"
                                    onClick={_handePayment}
                                    id="btnPayment"
                                    disabled={loadingData || loadingCreate || loadingUpdate}
                                    loading={loadingData || loadingCreate || loadingUpdate}
                            >
                                {t('button.paymentTicket')}
                            </Button>
                        }

                        {/*{!onlyView &&
                          <Button type="primary" icon={<SaveOutlined />}
                                  onClick={_handeSaveContinue}
                                  id="btnSaveAndContinue"
                                  disabled={loadingData || loadingCreate || loadingUpdate}
                                  loading={loadingData || loadingCreate || loadingUpdate}
                          >
                              {t('button.saveContinue')}
                          </Button>
                      }*/}

                        {(permission && permission.change && id && onlyView) &&
                            <Button type="primary"
                                    id='btnUpdate'
                                    onClick={_handeChange}
                                    icon={<EditOutlined/>}
                                    disabled={loadingData || loadingCreate || loadingUpdate}
                                    loading={loadingData || loadingCreate || loadingUpdate}
                            >
                                {t('button.edit')}
                            </Button>
                        }

                        <Button
                            type="default"
                            icon={<CloseOutlined/>}
                            onClick={_handeCancel}
                            id="btnBack"
                        >
                            {t('button.back')}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </div>
    )
}

export default HeaderAction