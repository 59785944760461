import React from 'react';
import {Flex, Spin} from 'antd';
import {useTranslation} from 'react-i18next';

const PageLoader = () => {
    const {t} = useTranslation();
    return (
        <Flex gap="middle" align="center" vertical>
            <Flex align="center" justify="center" className="page-loader-custom">
                <Flex gap="middle">
                    <Spin tip={t('message.Loading')} size="large">
                        <div style={{padding: 100, background: 'rgba(0, 0, 0, 0)', borderRadius: 4}}/>
                    </Spin>
                </Flex>
            </Flex>
        </Flex>
    )
};

export default PageLoader;
