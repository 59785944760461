import {RestLink} from 'apollo-link-rest';
import {ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client';
import * as _ from 'lodash';

class EInvoiceClients {
    constructor() {
        if (EInvoiceClients.hasInstance) {
            return EInvoiceClients.hasInstance
        }
        EInvoiceClients.hasInstance = this;

        this.clients = {}
    }

    init(publisher, uri, tokenAuth) {
        const restLink = new RestLink({uri});
        const authRestLink = new ApolloLink((operation, forward) => {
            operation.setContext(({headers}) => {
                return {
                    headers: {
                        ...headers,
                        Accept: "application/json",
                        ...(!_.isEmpty(tokenAuth) && {
                            Authorization: `${publisher === 'mobifone' ? 'Bear' : 'Bearer'} ${tokenAuth}`
                        })
                    }
                };
            });

            return forward(operation).map(result => {
                return result;
            });
        });

        this.clients[publisher] = new ApolloClient({
            cache: new InMemoryCache(),
            link: ApolloLink.from([authRestLink, restLink])
        });
    }

    createEInvoiceClient(publisher, link = null, token = null) {
        const uri = link ? link : JSON.parse(localStorage.getItem('openpos-store-key')).einvoices[publisher]?.link;
        const tokenAuth = token ? token : JSON.parse(localStorage.getItem('openpos-store-key')).einvoices[publisher]?.token;
        if (uri !== link || (token && token !== tokenAuth) || !this.clients[publisher]) {
            this.init(publisher, uri, tokenAuth);
        } else {
            console.log('EInvoiceClients created with ' + publisher);
        }
    }

    updateEInvoiceClient(publisher, link = null, token = null) {
        if (!this.clients[publisher]) {
            console.error('EInvoiceClients not found');
        } else {
            const uri = link ? link : JSON.parse(localStorage.getItem('openpos-store-key')).einvoices[publisher]?.link;
            const tokenAuth = token ? token : JSON.parse(localStorage.getItem('openpos-store-key')).einvoices[publisher]?.token;

            this.init(publisher, uri, tokenAuth);
        }
    }

    getEInvoiceClient(publisher) {
        if (!this.clients[publisher]) {
            createEInvoiceClient(publisher, null, null);
            return this.clients[publisher];
        } else {
            console.log(`Returning available ${publisher} client`)
            return this.clients[publisher];
        }
    }
}

// Lấy client cho nhà phát hành HDDT
export const eInvoiceClient = (publisher) => {
    const clients = new EInvoiceClients();

    return clients.getEInvoiceClient(publisher);
}

// Khởi tạo client cho nhà phát hành HDDT
export const createEInvoiceClient = (publisher, link = null, token = null) => {
    const clients = new EInvoiceClients();
    clients.createEInvoiceClient(publisher, link, token);
}

// Khởi tạo client cho nhà phát hành HDDT
export const updateEInvoiceClient = (publisher, link = null, token = null) => {
    const clients = new EInvoiceClients();

    return clients.updateEInvoiceClient(publisher, link, token);
}
