import React, {useEffect, useState} from "react";

const Logout = (props) => {
    localStorage.setItem('token', '');

    const resetEInvoice = () => {
        props.actions.changeEInvoiceToken('mobifone', '');
        props.actions.changeEInvoiceLink('mobifone', '');
        props.actions.changeEInvoiceToken('viettel', '');
        props.actions.changeEInvoiceLink('viettel', '');
        props.actions.changeEInvoiceToken('vnpt', '');
        props.actions.changeEInvoiceLink('vnpt', '');
    }

    //reset trang thái cho lần đăng nhập tiếp theo sẽ lấy dữ liệu mới
    props.actions.changeSetting('isFirstLoad', true);
    if (props.actions.changeEInvoiceToken) {
        resetEInvoice();
    }
    props.actions.changeUserLogin("username", "");
    props.actions.changeUserLogin("exp", 0);
    props.actions.changeUserLogin("origIat", 0);
    props.actions.changeUserLogin("token", "");
    props.actions.changeUserLogin("refreshExpiresIn", 0);
    props.actions.changeUserLogin("isAuthenticated", false);
    props.actions.changeUserLogin("firstName", "");
    props.actions.changeUserLogin("lastName", "");
    props.actions.changeUserLogin("isSuperuser", false);
    props.actions.changeUserLogin("isStaff", false);
    props.actions.changeUserLogin("isActive", false);
    props.actions.changeUserLogin("email", "");
    props.actions.changeUserLogin("groups", []);
    props.actions.changeUserLogin("userPermissions", []);
    props.actions.changeUserLogin("lastLogin", null);
    props.actions.changeUserLogin("dateJoined", null);
    props.actions.changeUserLogin("staff", {});
    props.actions.changeUserLogin("branchActivate", {});
    props.actions.changeUserLogin("permissions", []);
    props.actions.changeUserLogin("branches", []);
    props.actions.changeUserLogin("company", {});
    props.actions.changeMenu("menus", []);

    props.history.push('/login');
};

export default Logout;