import gpl from "graphql-tag";
import {userFieldDefault, userFieldModel} from "../fragments/userFragment";

export const LOGIN_MUTATION = gpl`
  mutation tokenAuth(
    $username: String!
    $password: String!
  ){
      tokenAuth(
          username: $username
          password: $password
      ){
        payload
        refreshExpiresIn
        token
        user{
            id
            firstName
            lastName
            isSuperuser
            isStaff
            isActive
            email
            groups{
              id
              name
              permissions{
                id
                name
                codename
              }
            }
            userPermissions{
              id
              name
              codename
            }
            lastLogin
            dateJoined
            configUser{
                  configId
                  configCode
                  configName
                  configValue
            }
            StaffsStaffUser{
              staffId
              staffCode
              staffAvatar
              staffName
              staffEmail
              staffPhone
              staffOfBranch{
                branchId
                branchCode
                branchName
                branchAddress
                branchAvatar
                branchEmail
                branchMobile
                branchStatus
                branchEnable
                branchManager{
                  staffId
                }
                branchTypeOfBusiness{
                    tobId
                    tobCode
                    tobName
                }
                branchOfCompany{
                    companyId
                    companyCode
                    companyName
                    companyLogo
                    companyCouchdbName
                    companyCouchdbUser
                    companyCouchdbPassword
                    companyOwner{
                        staffId
                    }
                }
              }
              companyCompanyOwner{
                companyId
                companyCode
                companyName
                companyLogo
                companyCouchdbName
                companyCouchdbUser
                companyCouchdbPassword
                companyExpiryDate
              }
              branchesSet{
                branchId
                branchCode
                branchName
                branchAddress
                branchAvatar
                branchEmail
                branchMobile
                branchStatus
                branchManager{
                  staffId
                }
                branchEnable
                branchOfCompany{
                    companyId
                    companyLogo
                    companyCode
                    companyName
                    companyCouchdbName
                    companyCouchdbUser
                    companyCouchdbPassword
                    companyOwner{
                        staffId
                    }
                }
                branchTypeOfBusiness{
                    tobId
                    tobCode
                    tobName
                }
              }
            }
            UserExtend{
                status
                userCompany{
                    companyId
                    companyIdTaxNumber
                    companyName
                    companyCode
                    companyLogo
                    companyExpiryDate
                    companyCouchdbName
                    companyCouchdbUser
                    companyCouchdbPassword
                    companyOwner{
                        staffId
                        staffCode
                        staffName
                    }
                    companyManager{
                        id
                        username
                        firstName
                        lastName
                    }
                }
            }
        }
      }
    }
`;

// Khôi phục mật khẩu
export const RECOVER_PASSWORD_MUTATION = gpl`
  mutation recoverPassword($username: String!){
      recoverPassword(username: $username){
        errors
        status
        message
      }
    }
`;

// Khôi phục mật khẩu
export const RESET_PASSWORD_MUTATION = gpl`
  mutation resetPassword($password: String!, $token: String!, $uidb64: String!){
      resetPassword(password: $password, token: $token, uidb64: $uidb64){
        errors
        status
        message
      }
    }
`;

// Người dùng tự đổi mật khẩu của mình
export const CHANGE_USER_PASSWORD = gpl`
   mutation changeUserPassword(
       $currentPassword: String
       $newPassword: String
       $retypePassword: String
   ){
    changeUserPassword(
        currentPassword: $currentPassword
        newPassword: $newPassword
        retypePassword: $retypePassword
    ){
        errors
    }
   }
`;

// Người dùng đổi mật khẩu của người dùng khác (nếu có quyền)
export const CHANGE_PASSWORD = gpl`
   mutation changePassword(
       $userId: Int
       $password: String
   ){
    changePassword(
        userId: $userId,
        password: $password
    ){
        errors
        status
    }
   }
`;

// Người dùng đăng ký cửa hàng
export const REGISTER_SHOP_MUTATION = gpl`
  mutation registerShop(
    $registerShopArgs: ShopRegisterArgs
  ){
      registerShop(
          registerShopArgs: $registerShopArgs
      ){
        errors
        status
      }
    }
`;

// Tạo khu vực bán hàng
export const USER_CREATE_MUTATION = gpl`
  ${userFieldModel}
  mutation createUser(
    $userArgs: UserArgs
  ){
      createUser(
        userArgs: $userArgs
      ){
        errors
        user{
            ...userFieldModel
        }
      }
    }
`;

// Sửa khu vực bán hàng
export const USER_UPDATE_MUTATION = gpl`
  ${userFieldModel}
  mutation updateUser($userArgs: UserArgs){
      updateUser(
        userArgs: $userArgs
      ){
        errors
        user{
            ...userFieldModel
        }
      }
    }
`;

// Kích hoạt/Vô hiệu hóa khu vực bán hàng
export const USER_ENABLE_MUTATION = gpl`
    mutation enableUser($usersParam: [UserEnableInput]){
      enableUser(usersParam: $usersParam){
        errors
      }
    }
`;

// Xóa mềm khu vực bán hàng
export const USER_DELETE_MUTATION = gpl`
    mutation deleteUser($ids: [Int]){
      deleteUser(ids: $ids){
        errors
        users{
            id
        }
      }
    }
`;

// Đăng xuất
export const LOGOUT_MUTATION = gpl`
    mutation revokeToken($token: String!) {
      revokeToken(token: $token) {
        success
        message
      }
    }
`;
