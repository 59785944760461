import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "../../../store/actions/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {useQuery} from "@apollo/client";
import DataEmpty from "../../Common/DataEmpty";
import {Button, Modal, Select, Space} from "antd";
import {COFFERS_QUERY} from "../../../api/coffers/cofferQuery";
import {PlusOutlined} from "@ant-design/icons";
import CofferForm from "../../Organisms/Form/Coffer/CofferForm";

const SelectCoffer = (props) => {
    const [idUpdateSelected, setIdUpdateSelected] = useState(props.values ? parseInt(props.values) : null);
    const [permission] = useState({
        //Thêm mới
        add: props.user.permissions.includes("add_cashier"),
        //Cập nhật
        change: props.user.permissions.includes("change_cashier"),
        //Xóa
        delete: props.user.permissions.includes("delete_cashier"),
        //Xem danh sách
        view: props.user.permissions.includes("view_cashier"),
        //Xem chi tiết
        detail: props.user.permissions.includes("detail_cashier")
    });
    const [coffers, setCoffers] = useState([]);
    const [showButtonCreate] = useState(props.showButtonCreate ? props.showButtonCreate : false);
    const [modalCreate, setModalCreate] = useState(false);

    const onChange = (data) => {
        let cofferId = null;
        if (data !== null && !isNaN(data)) {
            cofferId = data;
        }

        setIdUpdateSelected(cofferId);

        props.onChange(data);
    };

    const toggleModalCreate = () => {
        setModalCreate(!modalCreate);
    };

    const handleSave = (data, saveContinue = false) => {
        if (data) {
            let coffer = data.createCoffer.coffer;
            let cofferList = coffers;

            let newCoffer = {
                label: <div className="media">
                    <div className="media-body text-truncate">
                        <p className="mb-1">
                            <strong className="text-bold">
                                <span>{coffer.cofferName}</span>
                            </strong>
                        </p>
                        <p className="mb-1 text-sm">
                            <span className="text-sm">
                                {coffer.cofferBranch ? coffer.cofferBranch.branchName : ""}
                            </span>
                        </p>
                    </div>
                </div>,
                value: coffer.cofferId,
                cofferName: coffer.cofferName,
            };

            cofferList.push(newCoffer);

            setCoffers(cofferList);

            props.onChange(coffer.cofferId);

            toggleModalCreate()

            if (saveContinue) {
                setTimeout(() => {
                    setModalCreate(true)
                }, 500)
            }
        }
    };

    const {loading, data} = useQuery(COFFERS_QUERY, {
        variables: {branchId: props.user.branchActivate ? parseInt(props.user.branchActivate.branchId) : 0},
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (data) {
            let options = [];
            data.coffers.map((coffer) =>
                options.push({
                    label: coffer.cofferName,
                    value: coffer.cofferId,
                    cofferBranch: coffer.cofferBranch ? coffer.cofferBranch.branchName : "",
                    cofferName: coffer.cofferName,
                })
            );
            setCoffers(options)
        }
        if (props.values) {
            onChange(props.values);
        }
    }, [data, props.values]);

    return (
        <>
            <Space.Compact block>
                <Select {...props}
                        placeholder="Chọn két tiền"
                        optionLabelProp="label"
                        optionRender={(option) => (
                            <>
                                <Space wrap>
                                    <label>{option.data.cofferName}</label>
                                    <p className="mb-1 text-sm">
                                        {option.data.cofferBranch}
                                    </p>
                                </Space>
                            </>
                        )}
                        onChange={onChange}
                        options={coffers}
                        loading={loading}
                        allowClear
                        showSearch={true}
                        filterOption={false}
                        notFoundContent={() => <DataEmpty/>}
                        style={{minWidth: '150px'}}
                />
            </Space.Compact>

            {(showButtonCreate && permission.add) &&
                <Button type="primary" tabIndex="-1"
                        title="Tạo két giữ tiền"
                        onClick={toggleModalCreate}
                        icon={<PlusOutlined/>}
                >
                </Button>
            }

            <Modal open={modalCreate} onCancel={toggleModalCreate} footer={null}>
                <CofferForm id={null}
                            onError={() => {
                            }}
                            onCompleted={handleSave}
                            onGoHome={() => {
                            }}
                            onCancel={toggleModalCreate}
                            onDenied={() => {
                            }}
                />
            </Modal>
        </>
    )
};

SelectCoffer.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    configs: PropTypes.object,
    user: PropTypes.object,
    menus: PropTypes.object,
};

const mapStateToProps = state => ({
    settings: state.settings,
    configs: state.configs,
    user: state.user,
    menus: state.menus
});
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SelectCoffer));
